import React, { useState, useEffect, Suspense, useMemo, useCallback, useRef } from 'react'
import { StaticImage } from "gatsby-plugin-image";
import { useProgress } from '@react-three/drei'
import loadable from '@loadable/component'

const Logo3D = loadable(() => import('./logo3D'))


const Logo = ({ secretMode }) => {
  const [loaded, setLoaded] = useState(false)
  const [opaque, setOpaque] = useState(false)
  const { progress, errors, item } = useProgress()

  useEffect(() => {
    if (progress >= 100) {
      setLoaded(true)
    }
  }, [progress])

  useEffect(() => {
    if (errors.length > 0) {
      console.log(errors)
    }
  }, [errors])

  useEffect(() => {
    let t
    if (loaded !== opaque) t = setTimeout(() => setOpaque(loaded), 500)

    return () => clearTimeout(t)
  }, [loaded, opaque])

  return (
    <div className="mainheader__logo-container">
      <div className="mainheader__fallback-container" style={{ opacity: opaque ? 0 : 1 }}>
        <svg className="mainheader__fallback" width="512" height="76" viewBox="0 0 512 76" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.7152 23.5469L53.5057 63.041C54.3766 64.6444 54.8258 65.8538 54.8535 66.6694C54.9121 68.3964 53.5737 70.0027 50.8385 71.4882C49.2351 72.3591 47.9537 72.8108 46.9942 72.8433C46.0828 72.8742 45.3079 72.6844 44.6696 72.2738C44.0793 71.8615 43.6321 71.4204 43.328 70.9505C43.0718 70.4789 42.6877 69.7715 42.1754 68.8283L38.17 61.1837L18.2374 61.8597L14.7591 69.7581C14.312 70.7338 13.9518 71.4424 13.6786 71.8839C13.4534 72.3238 13.0371 72.7942 12.4297 73.2951C11.8687 73.7463 11.1084 73.9883 10.149 74.0208C9.2375 74.0517 7.9524 73.6871 6.29369 72.9268C3.46573 71.678 2.02247 70.19 1.9639 68.463C1.93625 67.6475 2.30253 66.4104 3.06274 64.7516L21.1274 23.8679C21.6177 22.7466 22.3792 21.8323 23.4118 21.1249C24.4924 20.4158 25.6323 20.041 26.8316 20.0003C29.4221 19.9125 31.3833 21.0947 32.7152 23.5469ZM27.4722 41.0147L23.0573 51.0341L32.6278 50.7095L27.4722 41.0147Z" fill="#866A2D" />
          <path d="M79.6614 17.2722C80.0208 16.5396 80.6513 16.0139 81.553 15.6952C82.4547 15.3764 83.6492 15.1918 85.1363 15.1414C86.6714 15.0893 87.8996 15.1918 88.8208 15.4487C89.7421 15.7056 90.3828 16.1882 90.743 16.8964C91.1512 17.6029 91.389 18.2432 91.4565 18.8173C91.5704 19.3417 91.646 20.1556 91.6834 21.259L93.1403 64.2183C93.1777 65.3217 93.1582 66.1628 93.0818 66.7417C93.0517 67.2711 92.8562 67.878 92.4952 68.5627C91.8195 69.8823 89.8026 70.5991 86.4446 70.713C83.1345 70.8252 81.2477 69.7125 80.7842 67.3749C78.8474 69.7459 76.0801 70.9924 72.4822 71.1144C68.1646 71.2609 64.1675 69.5233 60.4906 65.9019C56.8121 62.2324 54.8883 57.9032 54.7191 52.914C54.5483 47.8769 56.1736 43.4033 59.595 39.4931C63.0644 35.5812 66.9339 33.5529 71.2034 33.4081C74.7534 33.2877 77.5525 34.3935 79.6007 36.7254L79.0882 21.6141C79.0508 20.5107 79.0471 19.6944 79.0772 19.1651C79.1536 18.5861 79.3484 17.9552 79.6614 17.2722ZM69.3292 56.741C70.6204 57.994 72.1535 58.5904 73.9285 58.5302C75.7034 58.47 77.1446 57.7727 78.2521 56.4384C79.4074 55.1025 79.9591 53.667 79.907 52.1319C79.8534 50.5488 79.2281 49.1051 78.0312 47.801C76.8823 46.4952 75.3483 45.8748 73.4295 45.9399C71.5106 46.0049 69.9982 46.7286 68.8924 48.1109C67.785 49.4452 67.2582 50.9039 67.3118 52.487C67.3655 54.0701 68.038 55.4881 69.3292 56.741Z" fill="#866A2D" />
          <path d="M122.547 35.6292C122.744 32.9329 124.594 31.5255 128.096 31.4067C129.966 31.3432 131.363 31.44 132.284 31.6969C133.205 31.9538 133.846 32.4364 134.206 33.1446C134.614 33.8512 134.852 34.4915 134.919 35.0655C135.033 35.59 135.109 36.4039 135.146 37.5072L136 62.6927C136.038 63.7961 136.018 64.6373 135.942 65.2162C135.912 65.7455 135.741 66.3756 135.43 67.1066C134.8 68.3767 133.167 69.0565 130.528 69.1459C127.938 69.2338 126.176 68.9813 125.243 68.3886C124.357 67.7463 123.821 66.8039 123.635 65.5615C121.946 68.1642 119.015 69.5364 114.841 69.6779C110.715 69.8178 106.813 68.0531 103.135 64.3836C99.4564 60.7142 97.5326 56.3849 97.3634 51.3958C97.1926 46.3587 98.8187 41.909 102.242 38.0468C105.711 34.1349 109.581 32.1066 113.85 31.9618C115.481 31.9065 116.978 32.1439 118.341 32.674C119.704 33.2042 120.658 33.7241 121.203 34.2339C121.747 34.6957 122.195 35.1608 122.547 35.6292ZM109.959 51.0407C110.012 52.6238 110.685 54.0418 111.976 55.2948C113.266 56.4998 114.798 57.0721 116.573 57.012C118.348 56.9518 119.789 56.2545 120.896 54.9202C122.052 53.5843 122.603 52.1488 122.551 50.6136C122.498 49.0306 121.872 47.5869 120.675 46.2827C119.527 44.9769 117.993 44.3566 116.074 44.4216C114.203 44.4851 112.69 45.2088 111.537 46.5927C110.431 47.9749 109.905 49.4576 109.959 51.0407Z" fill="#866A2D" />
          <path d="M153.215 35.5977C155.354 32.1152 158.007 30.3203 161.173 30.2129C166.066 30.047 169.783 32.0101 172.323 36.1024C172.826 35.3649 173.424 34.6002 174.118 33.8082C174.858 32.9666 176.028 32.0625 177.628 31.0957C179.227 30.0809 180.889 29.5443 182.616 29.4857C186.454 29.3555 189.719 30.7337 192.41 33.6201C195.1 36.4586 196.56 41.2839 196.791 48.0959L197.218 60.6887C197.256 61.7921 197.236 62.6332 197.16 63.2121C197.13 63.7415 196.935 64.3724 196.576 65.105C195.996 66.4214 194.171 67.1317 191.101 67.2358C188.078 67.3383 186.209 66.7293 185.492 65.4089C185.131 64.7007 184.894 64.0844 184.781 63.5599C184.713 62.9859 184.661 62.1472 184.623 61.0438L184.196 48.451C184.055 44.2774 182.497 42.2411 179.522 42.3419C177.843 42.3989 176.711 42.9896 176.124 44.1141C175.586 45.237 175.351 46.8059 175.42 48.8207L175.847 61.4135C175.886 62.5648 175.866 63.406 175.788 63.9369C175.758 64.4662 175.563 65.0972 175.204 65.8298C174.576 67.1478 172.727 67.8589 169.657 67.963C166.635 68.0655 164.765 67.4566 164.048 66.1361C163.688 65.4279 163.451 64.8116 163.337 64.2871C163.269 63.7131 163.217 62.8744 163.179 61.771L162.752 49.1783C162.611 45.0047 161.053 42.9683 158.079 43.0692C155.2 43.1668 153.832 45.3024 153.973 49.476L154.405 62.2127C154.443 63.316 154.423 64.1572 154.347 64.7361C154.317 65.2654 154.122 65.8964 153.763 66.629C153.085 67.9007 151.236 68.5878 148.213 68.6903C145.191 68.7927 143.322 68.2078 142.607 66.9353C142.246 66.2271 142.009 65.6108 141.896 65.0863C141.83 64.5602 141.777 63.7215 141.738 62.5702L140.879 37.2408C140.842 36.1374 140.838 35.321 140.868 34.7917C140.945 34.2128 141.163 33.581 141.524 32.8964C142.2 31.5767 143.881 30.8714 146.568 30.7803C149.254 30.6891 151.02 31.0615 151.864 31.8974C152.709 32.7332 153.159 33.9667 153.215 35.5977Z" fill="#866A2D" />
          <path d="M243.057 38.9626C250.636 45.7656 256.021 50.89 259.212 54.3359C260.855 56.0572 261.698 57.5415 261.74 58.7887C261.781 59.9881 260.87 61.4598 259.008 63.2039C257.145 64.9 255.589 65.7692 254.342 65.8115C253.143 65.8522 251.645 64.8944 249.85 62.9381L233.407 45.4134L233.882 59.4453C233.921 60.5967 233.902 61.4378 233.824 61.9688C233.794 62.4981 233.599 63.129 233.24 63.8616C232.612 65.1797 230.763 65.8908 227.693 65.9949C224.335 66.1088 222.337 65.264 221.699 63.4606C221.386 62.7028 221.2 61.4843 221.143 59.8053L219.786 19.7963C219.749 18.6929 219.745 17.8766 219.775 17.3473C219.852 16.7683 220.07 16.1126 220.429 15.38C221.057 14.0619 222.906 13.3509 225.976 13.2467C229.334 13.1329 231.355 13.9528 232.039 15.7067C232.306 16.5141 232.469 17.7573 232.526 19.4363L232.992 33.1804C239.267 25.6194 244.34 19.4199 248.21 14.5819C249.822 12.5581 251.252 11.5251 252.499 11.4828C253.747 11.4405 255.358 12.2263 257.334 13.8403C259.308 15.4062 260.317 16.8129 260.359 18.0602C260.4 19.2595 259.729 20.723 258.347 22.4509C255.673 25.7594 251.505 30.3193 245.843 36.1305L243.057 38.9626Z" fill="#866A2D" />
          <path d="M287.898 30.0216C288.094 27.3254 289.944 25.9179 293.446 25.7992C295.317 25.7357 296.713 25.8325 297.634 26.0894C298.555 26.3463 299.196 26.8289 299.556 27.5371C299.964 28.2436 300.202 28.884 300.27 29.458C300.384 29.9824 300.459 30.7963 300.497 31.8997L301.351 57.0852C301.388 58.1886 301.369 59.0297 301.292 59.6087C301.262 60.138 301.091 60.7681 300.78 61.4991C300.151 62.7692 298.517 63.4489 295.878 63.5384C293.288 63.6263 291.526 63.3738 290.594 62.7811C289.707 62.1388 289.171 61.1964 288.985 59.954C287.296 62.5567 284.365 63.9289 280.191 64.0704C276.066 64.2103 272.164 62.4456 268.485 58.7761C264.807 55.1067 262.883 50.7774 262.714 45.7883C262.543 40.7512 264.169 36.3015 267.592 32.4393C271.061 28.5274 274.931 26.4991 279.2 26.3543C280.831 26.299 282.328 26.5364 283.691 27.0665C285.054 27.5966 286.008 28.1166 286.554 28.6264C287.098 29.0882 287.546 29.5533 287.898 30.0216ZM275.309 45.4332C275.363 47.0163 276.035 48.4343 277.326 49.6873C278.616 50.8922 280.148 51.4646 281.923 51.4044C283.698 51.3442 285.139 50.647 286.247 49.3127C287.402 47.9768 287.954 46.5412 287.902 45.0061C287.848 43.423 287.223 41.9794 286.026 40.6752C284.877 39.3694 283.343 38.749 281.424 38.8141C279.553 38.8776 278.041 39.6012 276.887 40.9851C275.781 42.3674 275.255 43.8501 275.309 45.4332Z" fill="#866A2D" />
          <path d="M331.793 24.931C332.714 25.1879 333.475 25.6664 334.075 26.3664C334.721 27.0169 335.071 28.1097 335.123 29.6448C335.175 31.1799 334.776 32.8744 333.926 34.7283C333.077 36.5821 331.788 37.5384 330.061 37.5969C329.198 37.6262 328.352 37.4628 327.523 37.1067C326.743 36.7489 325.705 36.592 324.409 36.6359C323.114 36.6799 321.906 37.1771 320.786 38.1276C319.714 39.0765 319.199 40.1986 319.243 41.4938L319.756 56.6051C319.793 57.7085 319.774 58.5497 319.697 59.1286C319.667 59.6579 319.472 60.2889 319.113 61.0215C318.436 62.2932 316.586 62.9802 313.564 63.0827C311.261 63.1608 309.592 62.8092 308.557 62.0278C307.767 61.3822 307.3 60.3655 307.157 58.9775C307.141 58.4978 307.117 57.8022 307.086 56.8907L306.229 31.6332C306.192 30.5299 306.188 29.7135 306.218 29.1842C306.295 28.6053 306.49 27.9743 306.803 27.2913C307.43 25.9733 309.279 25.2622 312.35 25.1581C315.228 25.0605 317.046 25.5512 317.803 26.6301C318.356 27.3798 318.647 28.1624 318.675 28.9779C318.903 28.634 319.225 28.2148 319.641 27.7204C320.104 27.2245 321.066 26.5435 322.525 25.6775C323.984 24.8116 325.266 24.3599 326.369 24.3224C327.521 24.2834 328.483 24.3228 329.255 24.4407C330.026 24.5106 330.872 24.674 331.793 24.931Z" fill="#866A2D" />
          <path d="M338.514 12.7442C338.477 11.6408 338.473 10.8244 338.503 10.2951C338.58 9.7162 338.773 9.06125 339.085 8.33028C339.712 7.01225 341.561 6.30117 344.632 6.19705C347.558 6.09781 349.403 6.68362 350.166 7.95447C350.575 8.66104 350.812 9.30134 350.88 9.87539C350.995 10.4478 351.072 11.2857 351.109 12.3891L352.239 45.7059C352.298 47.4329 352.479 48.5314 352.783 49.0014C353.086 49.4234 353.717 49.6181 354.676 49.5855C355.683 49.5514 356.357 49.5766 356.696 49.6611C357.033 49.6977 357.447 49.8758 357.939 50.1953C358.919 50.7864 359.457 52.4732 359.551 55.2556C359.654 58.2778 359.236 60.141 358.3 60.8452C357.082 61.751 354.112 61.9958 349.392 61.5795C345.681 61.2251 343.241 60.0831 342.071 58.1537C340.648 55.8486 339.85 52.1295 339.676 46.9965L338.514 12.7442Z" fill="#866A2D" />
          <path d="M363.905 57.9896C362.974 57.4448 362.487 56.5488 362.445 55.3015C362.403 54.0542 363.25 52.1284 364.987 49.524C365.487 48.6906 366.312 48.2544 367.464 48.2153C368.663 48.1747 370.29 48.7198 372.346 49.8508C374.4 50.9338 376.242 51.4477 377.873 51.3923C380.8 51.2931 382.243 50.6678 382.204 49.5165C382.173 48.605 380.543 47.9879 377.315 47.6651C374.278 47.3359 371.241 46.3102 368.205 44.5881C366.785 43.7718 365.588 42.4676 364.614 40.6756C363.64 38.8356 363.109 36.6443 363.023 34.1017C362.766 26.5221 367.842 22.5558 378.252 22.2027C381.658 22.0872 385.188 22.784 388.841 24.293C390.546 25.0037 391.418 25.9346 391.457 27.086C391.496 28.2373 391.017 29.6704 390.018 31.3852C389.02 33.1 387.969 33.9762 386.865 34.0136C386.29 34.0331 385.198 33.7099 383.591 33.044C382.031 32.3765 380.34 32.0736 378.517 32.1355C375.687 32.2314 374.289 32.8071 374.325 33.8625C374.374 35.3017 376.037 36.1819 379.313 36.503C382.491 36.7314 385.592 37.5388 388.617 38.925C390.031 39.5495 391.222 40.6858 392.191 42.3339C393.205 43.9324 393.755 45.979 393.84 48.4735C393.924 50.9681 393.517 53.1191 392.618 54.9266C391.765 56.6846 390.513 58.0237 388.864 58.9442C385.844 60.5355 382.007 61.41 377.354 61.5678C372.749 61.724 368.266 60.5312 363.905 57.9896Z" fill="#866A2D" />
          <path d="M418.979 34.2933L414.292 34.1641L414.629 44.0944C414.673 45.3896 414.896 46.3186 415.299 46.8813C415.751 47.4423 416.528 47.7041 417.631 47.6667C418.783 47.6276 419.648 47.6463 420.227 47.7227C420.852 47.7496 421.507 47.9435 422.192 48.3045C423.316 48.8907 423.922 50.4791 424.01 53.0696C424.113 56.0918 423.504 57.9615 422.183 58.6787C421.475 59.0389 420.835 59.2768 420.262 59.3922C419.736 59.4581 418.946 59.5089 417.89 59.5447C412.901 59.7139 409.073 58.7151 406.406 56.5482C403.739 54.3814 402.314 50.6115 402.132 45.2386L401.771 34.5887C400.864 34.7635 399.883 34.8689 398.828 34.9046C397.773 34.9404 396.871 34.5628 396.124 33.7716C395.425 32.9789 395.042 31.5991 394.975 29.6322C394.908 27.6653 394.98 26.2461 395.191 25.3744C395.4 24.4548 395.714 23.7958 396.133 23.3974C396.879 22.7477 397.828 22.4034 398.979 22.3643L401.364 22.5716L401.112 15.1599C401.075 14.0565 401.071 13.2402 401.101 12.7108C401.179 12.1799 401.4 11.5961 401.762 10.9594C402.345 9.73898 404.172 9.07669 407.242 8.97257C410.504 8.86194 412.477 9.68354 413.161 11.4374C413.427 12.1968 413.588 13.416 413.645 15.0951L413.884 22.147C415.989 21.8835 417.568 21.7339 418.624 21.6981C419.727 21.6607 420.544 21.681 421.075 21.7591C421.653 21.7875 422.308 21.9814 423.04 22.3408C424.309 22.9221 424.995 24.7479 425.099 27.8182C425.201 30.8404 424.616 32.7093 423.344 33.4249C422.636 33.7851 421.995 34.0229 421.423 34.1384C420.897 34.2042 420.082 34.2559 418.979 34.2933Z" fill="#866A2D" />
          <path d="M464.493 34.4788C464.592 37.4051 463.923 39.6131 462.484 41.1027C461.092 42.5427 459.509 43.2928 457.734 43.353L440.68 43.9313C440.725 45.2746 441.555 46.3751 443.169 47.2329C444.783 48.0906 446.381 48.4927 447.965 48.439C450.747 48.3447 452.92 47.9828 454.483 47.3534L455.265 47.0388C456.399 46.472 457.349 46.1756 458.117 46.1496C459.652 46.0975 461.008 47.1322 462.184 49.2535C462.852 50.5276 463.201 51.6204 463.232 52.5319C463.377 56.8014 458.34 59.1095 448.122 59.456C444.572 59.5764 441.386 59.0841 438.562 57.9792C435.786 56.8246 433.596 55.2659 431.992 53.3032C428.838 49.5199 427.18 45.2296 427.017 40.4324C426.812 34.3879 428.592 29.5008 432.355 25.771C436.165 21.9917 440.997 20.0028 446.849 19.8044C453.518 19.5782 458.49 21.7629 461.768 26.3585C463.484 28.7977 464.392 31.5045 464.493 34.4788ZM449.38 37.0805C451.203 37.0187 452.089 36.2202 452.037 34.6851C451.999 33.5817 451.537 32.7089 450.651 32.0665C449.813 31.4226 448.602 31.1275 447.019 31.1811C445.484 31.2332 443.971 31.9329 442.48 33.2802C440.987 34.5795 440.265 35.9488 440.314 37.388L449.38 37.0805Z" fill="#866A2D" />
          <path d="M489.781 19.1409C494.578 18.9782 498.623 20.6901 501.914 24.2766C505.252 27.8135 507.01 32.2204 507.189 37.4974L507.619 50.1621C507.656 51.2655 507.637 52.1066 507.56 52.6856C507.53 53.2149 507.359 53.821 507.045 54.504C506.418 55.8221 504.569 56.5332 501.499 56.6373C498.093 56.7528 496.096 55.932 495.508 54.1749C495.192 53.3691 495.007 52.1507 494.951 50.5197L494.522 37.8549C494.455 35.8881 493.828 34.3725 492.639 33.3082C491.498 32.2422 489.968 31.7418 488.049 31.8068C486.178 31.8703 484.663 32.498 483.502 33.69C482.39 34.8804 481.866 36.4111 481.929 38.282L482.361 51.0187C482.399 52.122 482.379 52.9632 482.303 53.5421C482.273 54.0714 482.077 54.6784 481.716 55.363C481.136 56.6794 479.311 57.3897 476.241 57.4938C473.219 57.5963 471.35 57.0113 470.634 55.7389C470.274 55.0307 470.037 54.4143 469.923 53.8899C469.856 53.3158 469.803 52.4771 469.766 51.3738L468.902 25.9004C468.866 24.845 468.863 24.0527 468.894 23.5233C468.97 22.9444 469.189 22.3126 469.55 21.628C470.181 20.4059 472.007 19.7436 475.029 19.6411C477.956 19.5419 479.773 20.0326 480.483 21.1132C480.99 21.9124 481.263 22.8877 481.302 24.0391C481.483 23.6968 481.898 23.2024 482.549 22.556C483.199 21.9096 483.83 21.3839 484.44 20.9789C486.034 19.8203 487.814 19.2076 489.781 19.1409Z" fill="#866A2D" />
          <path d="M32.7146 23.5131L53.5051 63.0071C54.376 64.6105 54.8252 65.82 54.8529 66.6355C54.9115 68.3625 53.5731 69.9688 50.8379 71.4543C49.2345 72.3252 47.9531 72.7769 46.9936 72.8094C46.0822 72.8403 45.3073 72.6505 44.669 72.2399C44.0787 71.8276 43.6315 71.3866 43.3274 70.9166C43.0712 70.445 42.687 69.7376 42.1748 68.7944L38.1694 61.1498L18.2368 61.8258L14.7585 69.7242C14.3114 70.6999 13.9512 71.4085 13.678 71.85C13.4528 72.2899 13.0365 72.7603 12.4291 73.2612C11.8681 73.7125 11.1078 73.9544 10.1484 73.9869C9.23689 74.0178 7.95179 73.6532 6.29308 72.8929C3.46512 71.6441 2.02186 70.1561 1.96329 68.4291C1.93564 67.6136 2.30192 66.3765 3.06213 64.7178L21.1268 23.834C21.6171 22.7127 22.3785 21.7984 23.4112 21.091C24.4918 20.382 25.6317 20.0071 26.831 19.9664C29.4215 19.8786 31.3827 21.0608 32.7146 23.5131ZM27.4716 40.9808L23.0567 51.0002L32.6272 50.6756L27.4716 40.9808Z" fill="#A37E2D" />
          <path d="M79.6608 17.2383C80.0202 16.5057 80.6507 15.98 81.5524 15.6613C82.4541 15.3425 83.6486 15.1579 85.1357 15.1075C86.6708 15.0554 87.899 15.1579 88.8202 15.4148C89.7415 15.6717 90.3822 16.1543 90.7424 16.8625C91.1506 17.569 91.3884 18.2093 91.4559 18.7834C91.5698 19.3078 91.6454 20.1217 91.6828 21.2251L93.1397 64.1844C93.1771 65.2878 93.1576 66.1289 93.0812 66.7078C93.0511 67.2372 92.8556 67.8441 92.4946 68.5288C91.8189 69.8484 89.802 70.5652 86.444 70.6791C83.1339 70.7913 81.2471 69.6786 80.7836 67.341C78.8468 69.712 76.0795 70.9585 72.4816 71.0805C68.164 71.227 64.1668 69.4894 60.49 65.868C56.8115 62.1986 54.8877 57.8693 54.7185 52.8801C54.5476 47.843 56.173 43.3694 59.5944 39.4592C63.0638 35.5473 66.9333 33.519 71.2028 33.3742C74.7528 33.2538 77.5519 34.3596 79.6001 36.6915L79.0876 21.5802C79.0502 20.4768 79.0465 19.6605 79.0766 19.1312C79.153 18.5522 79.3477 17.9213 79.6608 17.2383ZM69.3286 56.7072C70.6198 57.9601 72.1529 58.5565 73.9279 58.4963C75.7028 58.4361 77.144 57.7389 78.2514 56.4046C79.4068 55.0686 79.9585 53.6331 79.9064 52.098C79.8527 50.5149 79.2275 49.0713 78.0306 47.7671C76.8816 46.4613 75.3477 45.8409 73.4288 45.906C71.5099 45.9711 69.9976 46.6947 68.8918 48.077C67.7844 49.4113 67.2575 50.87 67.3112 52.4531C67.3649 54.0362 68.0374 55.4542 69.3286 56.7072Z" fill="#A37E2D" />
          <path d="M122.547 35.5953C122.744 32.8991 124.593 31.4916 128.095 31.3728C129.966 31.3094 131.362 31.4061 132.283 31.663C133.204 31.9199 133.845 32.4025 134.205 33.1107C134.614 33.8173 134.851 34.4576 134.919 35.0316C135.033 35.5561 135.108 36.37 135.146 37.4733L136 62.6589C136.037 63.7622 136.018 64.6034 135.941 65.1823C135.911 65.7116 135.741 66.3418 135.429 67.0727C134.8 68.3428 133.166 69.0226 130.527 69.112C127.937 69.1999 126.175 68.9475 125.243 68.3547C124.356 67.7124 123.82 66.77 123.634 65.5276C121.945 68.1304 119.014 69.5025 114.84 69.644C110.715 69.7839 106.813 68.0192 103.134 64.3498C99.4558 60.6803 97.532 56.351 97.3628 51.3619C97.1919 46.3248 98.8181 41.8751 102.241 38.0129C105.711 34.1011 109.58 32.0727 113.85 31.9279C115.481 31.8726 116.978 32.11 118.34 32.6402C119.703 33.1703 120.657 33.6902 121.203 34.2C121.747 34.6619 122.195 35.1269 122.547 35.5953ZM109.958 51.0068C110.012 52.5899 110.684 54.0079 111.975 55.2609C113.265 56.4659 114.797 57.0383 116.572 56.9781C118.347 56.9179 119.788 56.2206 120.896 54.8863C122.051 53.5504 122.603 52.1149 122.551 50.5798C122.497 48.9967 121.872 47.553 120.675 46.2488C119.526 44.943 117.992 44.3227 116.073 44.3877C114.202 44.4512 112.69 45.1749 111.536 46.5588C110.43 47.941 109.904 49.4237 109.958 51.0068Z" fill="#A37E2D" />
          <path d="M153.214 35.5638C155.353 32.0813 158.006 30.2864 161.172 30.179C166.065 30.0131 169.782 31.9762 172.322 36.0685C172.825 35.331 173.424 34.5663 174.117 33.7743C174.857 32.9328 176.027 32.0286 177.627 31.0618C179.226 30.047 180.889 29.5104 182.616 29.4518C186.454 29.3216 189.718 30.6998 192.41 33.5862C195.099 36.4247 196.56 41.25 196.791 48.0621L197.218 60.6548C197.255 61.7582 197.236 62.5993 197.159 63.1782C197.129 63.7076 196.934 64.3385 196.575 65.0711C195.995 66.3875 194.17 67.0978 191.1 67.2019C188.078 67.3044 186.208 66.6954 185.491 65.375C185.131 64.6668 184.894 64.0505 184.78 63.526C184.712 62.952 184.66 62.1133 184.623 61.0099L184.195 48.4172C184.054 44.2436 182.496 42.2072 179.522 42.3081C177.843 42.365 176.71 42.9557 176.124 44.0802C175.586 45.2031 175.351 46.772 175.419 48.7868L175.846 61.3796C175.885 62.5309 175.866 63.3721 175.788 63.903C175.757 64.4324 175.563 65.0633 175.203 65.7959C174.576 67.1139 172.727 67.825 169.656 67.9291C166.634 68.0316 164.764 67.4227 164.047 66.1022C163.687 65.394 163.45 64.7777 163.336 64.2532C163.269 63.6792 163.216 62.8405 163.179 61.7371L162.752 49.1444C162.61 44.9708 161.052 42.9344 158.078 43.0353C155.2 43.1329 153.831 45.2685 153.973 49.4421L154.405 62.1788C154.442 63.2821 154.423 64.1233 154.346 64.7022C154.316 65.2315 154.121 65.8625 153.762 66.5951C153.085 67.8668 151.235 68.5539 148.213 68.6564C145.191 68.7589 143.322 68.1739 142.606 66.9014C142.246 66.1932 142.009 65.5769 141.895 65.0524C141.829 64.5264 141.777 63.6876 141.738 62.5363L140.879 37.2069C140.841 36.1035 140.838 35.2872 140.868 34.7578C140.944 34.1789 141.163 33.5471 141.524 32.8625C142.199 31.5429 143.88 30.8375 146.567 30.7464C149.253 30.6553 151.019 31.0276 151.864 31.8635C152.709 32.6993 153.159 33.9328 153.214 35.5638Z" fill="#A37E2D" />
          <path d="M243.057 38.9287C250.636 45.7317 256.021 50.8562 259.211 54.302C260.854 56.0233 261.697 57.5076 261.74 58.7549C261.78 59.9542 260.87 61.4259 259.008 63.17C257.144 64.8661 255.589 65.7353 254.341 65.7776C253.142 65.8183 251.645 64.8605 249.849 62.9042L233.406 45.3795L233.882 59.4114C233.921 60.5628 233.901 61.4039 233.823 61.9349C233.793 62.4642 233.598 63.0952 233.239 63.8278C232.611 65.1458 230.762 65.8569 227.692 65.961C224.334 66.0749 222.336 65.2301 221.699 63.4267C221.385 62.6689 221.2 61.4504 221.143 59.7714L219.786 19.7624C219.748 18.659 219.745 17.8427 219.775 17.3134C219.851 16.7345 220.069 16.0787 220.429 15.3461C221.056 14.0281 222.905 13.317 225.975 13.2129C229.333 13.099 231.354 13.919 232.038 15.6728C232.306 16.4802 232.468 17.7234 232.525 19.4024L232.991 33.1465C239.266 25.5855 244.339 19.386 248.209 14.548C249.822 12.5242 251.252 11.4912 252.499 11.4489C253.746 11.4066 255.358 12.1924 257.334 13.8064C259.308 15.3724 260.316 16.779 260.358 18.0263C260.399 19.2256 259.728 20.6892 258.346 22.417C255.673 25.7255 251.505 30.2854 245.842 36.0966L243.057 38.9287Z" fill="#A37E2D" />
          <path d="M287.897 29.9878C288.094 27.2915 289.943 25.8841 293.445 25.7653C295.316 25.7018 296.712 25.7986 297.633 26.0555C298.555 26.3124 299.195 26.795 299.556 27.5032C299.964 28.2098 300.202 28.8501 300.269 29.4241C300.383 29.9485 300.459 30.7625 300.496 31.8658L301.35 57.0513C301.388 58.1547 301.368 58.9959 301.292 59.5748C301.262 60.1041 301.091 60.7342 300.779 61.4652C300.15 62.7353 298.516 63.415 295.878 63.5045C293.287 63.5924 291.526 63.3399 290.593 62.7472C289.707 62.1049 289.17 61.1625 288.984 59.9201C287.295 62.5228 284.364 63.895 280.191 64.0365C276.065 64.1764 272.163 62.4117 268.485 58.7422C264.806 55.0728 262.882 50.7435 262.713 45.7544C262.542 40.7173 264.168 36.2676 267.591 32.4054C271.061 28.4935 274.93 26.4652 279.2 26.3204C280.831 26.2651 282.328 26.5025 283.691 27.0326C285.053 27.5628 286.007 28.0827 286.553 28.5925C287.097 29.0543 287.545 29.5194 287.897 29.9878ZM275.308 45.3993C275.362 46.9824 276.034 48.4004 277.326 49.6534C278.615 50.8584 280.147 51.4307 281.922 51.3706C283.697 51.3104 285.139 50.6131 286.246 49.2788C287.401 47.9429 287.953 46.5074 287.901 44.9722C287.847 43.3891 287.222 41.9455 286.025 40.6413C284.876 39.3355 283.342 38.7152 281.423 38.7802C279.552 38.8437 278.04 39.5674 276.886 40.9513C275.781 42.3335 275.255 43.8162 275.308 45.3993Z" fill="#A37E2D" />
          <path d="M331.792 24.8971C332.714 25.154 333.474 25.6325 334.074 26.3326C334.721 26.983 335.07 28.0758 335.122 29.6109C335.174 31.146 334.775 32.8405 333.926 34.6944C333.076 36.5483 331.788 37.5045 330.061 37.563C329.197 37.5923 328.351 37.4289 327.523 37.0728C326.742 36.715 325.704 36.5581 324.409 36.6021C323.114 36.646 321.906 37.1432 320.785 38.0937C319.713 39.0426 319.199 40.1647 319.243 41.4599L319.755 56.5713C319.792 57.6746 319.773 58.5158 319.697 59.0947C319.666 59.624 319.472 60.255 319.112 60.9876C318.435 62.2593 316.585 62.9464 313.563 63.0489C311.26 63.1269 309.591 62.7753 308.556 61.9939C307.766 61.3484 307.299 60.3316 307.156 58.9436C307.14 58.4639 307.116 57.7683 307.085 56.8568L306.229 31.5994C306.191 30.496 306.188 29.6796 306.218 29.1503C306.294 28.5714 306.489 27.9404 306.802 27.2574C307.43 25.9394 309.279 25.2283 312.349 25.1242C315.227 25.0266 317.045 25.5173 317.802 26.5962C318.356 27.3459 318.646 28.1285 318.674 28.944C318.903 28.6001 319.224 28.1809 319.64 27.6866C320.103 27.1906 321.065 26.5096 322.524 25.6436C323.984 24.7777 325.265 24.326 326.369 24.2886C327.52 24.2495 328.482 24.2889 329.254 24.4068C330.025 24.4767 330.871 24.6401 331.792 24.8971Z" fill="#A37E2D" />
          <path d="M338.513 12.7103C338.476 11.6069 338.472 10.7906 338.502 10.2612C338.579 9.68231 338.773 9.02736 339.084 8.29639C339.712 6.97836 341.561 6.26728 344.631 6.16316C347.557 6.06392 349.402 6.64973 350.166 7.92058C350.574 8.62715 350.812 9.26746 350.879 9.8415C350.995 10.4139 351.071 11.2518 351.109 12.3552L352.239 45.672C352.297 47.399 352.478 48.4975 352.783 48.9675C353.085 49.3895 353.716 49.5842 354.675 49.5517C355.683 49.5175 356.356 49.5427 356.695 49.6272C357.033 49.6638 357.447 49.8419 357.938 50.1614C358.919 50.7525 359.456 52.4393 359.55 55.2217C359.653 58.2439 359.236 60.1071 358.299 60.8113C357.081 61.7171 354.112 61.9619 349.391 61.5457C345.681 61.1912 343.241 60.0492 342.071 58.1198C340.648 55.8147 339.849 52.0956 339.675 46.9626L338.513 12.7103Z" fill="#A37E2D" />
          <path d="M363.904 57.9557C362.973 57.4109 362.487 56.5149 362.444 55.2676C362.402 54.0203 363.249 52.0945 364.986 49.4902C365.486 48.6567 366.312 48.2205 367.463 48.1814C368.662 48.1408 370.29 48.6859 372.345 49.8169C374.399 50.8999 376.242 51.4138 377.873 51.3585C380.799 51.2592 382.243 50.6339 382.204 49.4826C382.173 48.5711 380.543 47.954 377.314 47.6312C374.277 47.302 371.241 46.2763 368.205 44.5542C366.784 43.7379 365.587 42.4337 364.614 40.6417C363.639 38.8017 363.108 36.6104 363.022 34.0679C362.765 26.4882 367.842 22.5219 378.252 22.1688C381.658 22.0533 385.187 22.7501 388.841 24.2591C390.546 24.9698 391.418 25.9007 391.457 27.0521C391.496 28.2034 391.016 29.6365 390.018 31.3513C389.019 33.0662 387.968 33.9423 386.865 33.9797C386.289 33.9992 385.198 33.676 383.59 33.0101C382.031 32.3426 380.339 32.0398 378.516 32.1016C375.686 32.1976 374.289 32.7733 374.325 33.8286C374.373 35.2678 376.036 36.148 379.313 36.4691C382.49 36.6975 385.592 37.5049 388.616 38.8912C390.03 39.5156 391.221 40.6519 392.19 42.3C393.205 43.8985 393.754 45.9451 393.839 48.4396C393.924 50.9342 393.516 53.0852 392.617 54.8928C391.764 56.6507 390.513 57.9899 388.863 58.9103C385.843 60.5016 382.007 61.3761 377.353 61.5339C372.748 61.6901 368.265 60.4973 363.904 57.9557Z" fill="#A37E2D" />
          <path d="M418.978 34.2594L414.291 34.1302L414.628 44.0605C414.672 45.3558 414.896 46.2847 415.299 46.8474C415.75 47.4084 416.527 47.6702 417.631 47.6328C418.782 47.5937 419.647 47.6124 420.226 47.6888C420.851 47.7157 421.506 47.9096 422.191 48.2706C423.316 48.8568 423.922 50.4452 424.01 53.0357C424.112 56.0579 423.503 57.9277 422.183 58.6448C421.474 59.005 420.834 59.2429 420.262 59.3583C419.736 59.4242 418.945 59.475 417.89 59.5108C412.9 59.68 409.072 58.6812 406.405 56.5143C403.738 54.3475 402.314 50.5776 402.132 45.2047L401.77 34.5548C400.864 34.7297 399.883 34.835 398.827 34.8708C397.772 34.9065 396.871 34.5289 396.124 33.7377C395.424 32.945 395.041 31.5652 394.975 29.5983C394.908 27.6314 394.98 26.2122 395.19 25.3406C395.399 24.4209 395.713 23.7619 396.132 23.3635C396.878 22.7138 397.827 22.3695 398.979 22.3304L401.363 22.5377L401.112 15.126C401.074 14.0226 401.07 13.2063 401.101 12.677C401.179 12.146 401.399 11.5622 401.762 10.9255C402.345 9.70509 404.171 9.0428 407.241 8.93868C410.504 8.82805 412.476 9.64965 413.16 11.4035C413.426 12.1629 413.588 13.3821 413.645 15.0612L413.884 22.1131C415.988 21.8496 417.568 21.7 418.623 21.6642C419.727 21.6268 420.544 21.6471 421.075 21.7252C421.652 21.7536 422.307 21.9475 423.04 22.3069C424.308 22.8882 424.994 24.714 425.098 27.7843C425.201 30.8065 424.616 32.6754 423.343 33.391C422.635 33.7512 421.995 33.989 421.422 34.1045C420.896 34.1704 420.082 34.222 418.978 34.2594Z" fill="#A37E2D" />
          <path d="M464.492 34.4449C464.592 37.3712 463.922 39.5792 462.484 41.0688C461.092 42.5088 459.508 43.2589 457.733 43.3191L440.679 43.8974C440.725 45.2407 441.554 46.3412 443.168 47.199C444.782 48.0568 446.381 48.4588 447.964 48.4051C450.746 48.3108 452.919 47.9489 454.483 47.3196L455.265 47.0049C456.398 46.4381 457.349 46.1417 458.116 46.1157C459.651 46.0637 461.007 47.0983 462.184 49.2196C462.851 50.4937 463.201 51.5865 463.231 52.498C463.376 56.7676 458.34 59.0756 448.121 59.4221C444.571 59.5425 441.385 59.0502 438.562 57.9453C435.785 56.7907 433.595 55.2321 431.992 53.2693C428.838 49.486 427.179 45.1957 427.017 40.3985C426.812 34.354 428.591 29.4669 432.355 25.7372C436.165 21.9578 440.996 19.9689 446.849 19.7705C453.517 19.5443 458.49 21.729 461.767 26.3246C463.483 28.7638 464.391 31.4706 464.492 34.4449ZM449.38 37.0466C451.203 36.9848 452.088 36.1863 452.036 34.6512C451.999 33.5478 451.537 32.675 450.651 32.0327C449.812 31.3887 448.602 31.0936 447.018 31.1473C445.483 31.1993 443.97 31.899 442.479 33.2463C440.986 34.5457 440.264 35.9149 440.313 37.3541L449.38 37.0466Z" fill="#A37E2D" />
          <path d="M489.78 19.107C494.578 18.9443 498.622 20.6562 501.913 24.2427C505.251 27.7796 507.01 32.1865 507.189 37.4635L507.618 50.1282C507.655 51.2316 507.636 52.0727 507.56 52.6517C507.529 53.181 507.358 53.7872 507.045 54.4702C506.417 55.7882 504.568 56.4993 501.498 56.6034C498.092 56.7189 496.095 55.8981 495.507 54.141C495.192 53.3352 495.006 52.1168 494.951 50.4858L494.521 37.8211C494.455 35.8542 493.827 34.3386 492.638 33.2743C491.497 32.2083 489.968 31.7079 488.049 31.773C486.178 31.8364 484.662 32.4641 483.502 33.6561C482.39 34.8465 481.865 36.3772 481.929 38.2481L482.361 50.9848C482.398 52.0882 482.379 52.9293 482.302 53.5082C482.272 54.0375 482.076 54.6445 481.715 55.3292C481.136 56.6456 479.311 57.3558 476.241 57.4599C473.218 57.5624 471.349 56.9774 470.634 55.705C470.274 54.9968 470.037 54.3804 469.923 53.856C469.855 53.282 469.803 52.4433 469.765 51.3399L468.901 25.8665C468.866 24.8111 468.863 24.0188 468.893 23.4895C468.969 22.9105 469.188 22.2788 469.549 21.5941C470.18 20.372 472.007 19.7097 475.029 19.6073C477.955 19.508 479.773 19.9987 480.482 21.0793C480.989 21.8785 481.262 22.8538 481.302 24.0052C481.482 23.6629 481.898 23.1685 482.548 22.5221C483.198 21.8757 483.829 21.35 484.44 20.9451C486.033 19.7864 487.814 19.1737 489.78 19.107Z" fill="#A37E2D" />
          <path d="M36.7462 24.3769L57.5368 63.8709C58.4076 65.4743 58.8569 66.6838 58.8845 67.4993C58.9431 69.2263 57.6047 70.8326 54.8695 72.3182C53.2661 73.189 51.9847 73.6407 51.0252 73.6733C50.1138 73.7042 49.3389 73.5143 48.7006 73.1037C48.1103 72.6915 47.6631 72.2504 47.359 71.7804C47.1029 71.3088 46.7187 70.6015 46.2064 69.6583L42.201 62.0136L22.2684 62.6896L18.7902 70.588C18.343 71.5638 17.9828 72.2724 17.7096 72.7139C17.4844 73.1538 17.0681 73.6242 16.4607 74.125C15.8997 74.5763 15.1394 74.8182 14.18 74.8507C13.2685 74.8817 11.9834 74.517 10.3247 73.7568C7.49674 72.5079 6.05348 71.02 5.99491 69.293C5.96725 68.4774 6.33353 67.2403 7.09375 65.5816L25.1584 24.6978C25.6487 23.5766 26.4102 22.6622 27.4428 21.9548C28.5234 21.2458 29.6633 20.8709 30.8626 20.8303C33.4531 20.7424 35.4143 21.9246 36.7462 24.3769ZM31.5032 41.8446L27.0883 51.864L36.6588 51.5395L31.5032 41.8446Z" fill="#FFCC59" />
          <path d="M83.6924 18.1021C84.0518 17.3695 84.6823 16.8439 85.584 16.5251C86.4857 16.2064 87.6802 16.0218 89.1673 15.9713C90.7024 15.9193 91.9306 16.0217 92.8518 16.2786C93.7731 16.5356 94.4138 17.0181 94.774 17.7263C95.1822 18.4329 95.42 19.0732 95.4875 19.6472C95.6014 20.1717 95.677 20.9856 95.7144 22.0889L97.1713 65.0483C97.2087 66.1516 97.1892 66.9928 97.1128 67.5717C97.0827 68.101 96.8872 68.708 96.5262 69.3926C95.8505 70.7123 93.8337 71.429 90.4756 71.5429C87.1655 71.6552 85.2787 70.5425 84.8152 68.2049C82.8784 70.5759 80.1111 71.8224 76.5132 71.9444C72.1957 72.0908 68.1985 70.3533 64.5216 66.7318C60.8431 63.0624 58.9193 58.7331 58.7501 53.744C58.5793 48.7069 60.2046 44.2332 63.626 40.323C67.0954 36.4112 70.9649 34.3829 75.2344 34.2381C78.7844 34.1177 81.5835 35.2234 83.6317 37.5554L83.1192 22.444C83.0818 21.3407 83.0781 20.5243 83.1082 19.995C83.1846 19.4161 83.3794 18.7851 83.6924 18.1021ZM73.3602 57.571C74.6514 58.824 76.1845 59.4203 77.9595 59.3601C79.7345 59.2999 81.1757 58.6027 82.2831 57.2684C83.4384 55.9325 83.9901 54.4969 83.938 52.9618C83.8844 51.3787 83.2591 49.9351 82.0622 48.6309C80.9133 47.3251 79.3794 46.7047 77.4605 46.7698C75.5416 46.8349 74.0292 47.5586 72.9234 48.9408C71.816 50.2751 71.2892 51.7338 71.3428 53.3169C71.3965 54.9 72.069 56.318 73.3602 57.571Z" fill="#FFCC59" />
          <path d="M126.578 36.4591C126.775 33.7629 128.625 32.3554 132.127 32.2366C133.997 32.1732 135.394 32.2699 136.315 32.5269C137.236 32.7838 137.877 33.2663 138.237 33.9745C138.645 34.6811 138.883 35.3214 138.95 35.8955C139.064 36.4199 139.14 37.2338 139.177 38.3372L140.031 63.5227C140.069 64.6261 140.049 65.4672 139.973 66.0461C139.943 66.5755 139.772 67.2056 139.461 67.9366C138.831 69.2066 137.198 69.8864 134.559 69.9759C131.969 70.0637 130.207 69.8113 129.274 69.2186C128.388 68.5762 127.852 67.6339 127.666 66.3915C125.977 68.9942 123.046 70.3663 118.872 70.5079C114.746 70.6478 110.844 68.883 107.166 65.2136C103.487 61.5442 101.564 57.2149 101.394 52.2258C101.224 47.1886 102.85 42.739 106.273 38.8767C109.742 34.9649 113.612 32.9366 117.881 32.7918C119.512 32.7365 121.009 32.9739 122.372 33.504C123.735 34.0341 124.689 34.5541 125.234 35.0639C125.778 35.5257 126.226 35.9908 126.578 36.4591ZM113.99 51.8707C114.043 53.4537 114.716 54.8718 116.007 56.1247C117.297 57.3297 118.829 57.9021 120.604 57.8419C122.379 57.7817 123.82 57.0845 124.927 55.7502C126.083 54.4142 126.634 52.9787 126.582 51.4436C126.529 49.8605 125.903 48.4169 124.706 47.1127C123.558 45.8069 122.024 45.1865 120.105 45.2516C118.234 45.315 116.721 46.0387 115.568 47.4226C114.462 48.8049 113.936 50.2876 113.99 51.8707Z" fill="#FFCC59" />
          <path d="M157.246 36.4277C159.385 32.9452 162.038 31.1502 165.204 31.0429C170.097 30.8769 173.814 32.8401 176.354 36.9323C176.857 36.1949 177.455 35.4301 178.149 34.6382C178.889 33.7966 180.059 32.8924 181.659 31.9256C183.258 30.9109 184.92 30.3742 186.647 30.3156C190.485 30.1855 193.75 31.5636 196.441 34.4501C199.131 37.2885 200.591 42.1138 200.822 48.9259L201.249 61.5187C201.287 62.622 201.267 63.4632 201.191 64.0421C201.161 64.5714 200.966 65.2024 200.607 65.935C200.027 67.2514 198.202 67.9617 195.132 68.0658C192.109 68.1683 190.24 67.5593 189.523 66.2388C189.162 65.5306 188.925 64.9143 188.812 64.3899C188.744 63.8158 188.692 62.9771 188.654 61.8738L188.227 49.281C188.086 45.1074 186.528 43.071 183.553 43.1719C181.874 43.2288 180.742 43.8196 180.155 44.9441C179.617 46.067 179.382 47.6358 179.451 49.6507L179.878 62.2434C179.917 63.3948 179.897 64.2359 179.819 64.7669C179.789 65.2962 179.594 65.9272 179.235 66.6598C178.607 67.9778 176.758 68.6889 173.688 68.793C170.666 68.8955 168.796 68.2865 168.079 66.9661C167.719 66.2579 167.482 65.6415 167.368 65.1171C167.3 64.543 167.248 63.7043 167.21 62.601L166.783 50.0082C166.642 45.8346 165.084 43.7983 162.11 43.8991C159.231 43.9967 157.863 46.1323 158.004 50.3059L158.436 63.0426C158.474 64.146 158.454 64.9871 158.378 65.5661C158.348 66.0954 158.153 66.7263 157.794 67.4589C157.116 68.7306 155.267 69.4177 152.244 69.5202C149.222 69.6227 147.353 69.0377 146.638 67.7652C146.277 67.057 146.04 66.4407 145.927 65.9163C145.861 65.3902 145.808 64.5515 145.769 63.4002L144.91 38.0707C144.873 36.9673 144.869 36.151 144.899 35.6217C144.976 35.0428 145.194 34.411 145.555 33.7264C146.231 32.4067 147.912 31.7013 150.599 31.6102C153.285 31.5191 155.051 31.8915 155.895 32.7273C156.74 33.5632 157.19 34.7966 157.246 36.4277Z" fill="#FFCC59" />
          <path d="M247.088 39.7925C254.667 46.5956 260.052 51.72 263.243 55.1658C264.886 56.8871 265.729 58.3714 265.771 59.6187C265.812 60.818 264.901 62.2897 263.039 64.0338C261.176 65.73 259.62 66.5992 258.373 66.6415C257.174 66.6822 255.676 65.7244 253.881 63.7681L237.438 46.2433L237.913 60.2753C237.952 61.4266 237.933 62.2678 237.855 62.7987C237.825 63.328 237.63 63.959 237.271 64.6916C236.643 66.0096 234.794 66.7207 231.724 66.8248C228.366 66.9387 226.368 66.0939 225.73 64.2905C225.417 63.5327 225.231 62.3143 225.174 60.6353L223.817 20.6263C223.78 19.5229 223.776 18.7065 223.806 18.1772C223.883 17.5983 224.101 16.9425 224.46 16.2099C225.088 14.8919 226.937 14.1808 230.007 14.0767C233.365 13.9628 235.386 14.7828 236.07 16.5366C236.337 17.344 236.5 18.5872 236.557 20.2663L237.023 34.0104C243.298 26.4493 248.371 20.2498 252.241 15.4119C253.853 13.3881 255.283 12.355 256.53 12.3127C257.778 12.2704 259.389 13.0562 261.365 14.6702C263.339 16.2362 264.348 17.6428 264.39 18.8901C264.431 20.0894 263.76 21.553 262.378 23.2808C259.704 26.5894 255.536 31.1492 249.874 36.9605L247.088 39.7925Z" fill="#FFCC59" />
          <path d="M291.929 30.8516C292.125 28.1554 293.975 26.7479 297.477 26.6291C299.348 26.5657 300.744 26.6624 301.665 26.9193C302.586 27.1763 303.227 27.6588 303.587 28.367C303.995 29.0736 304.233 29.7139 304.301 30.288C304.415 30.8124 304.49 31.6263 304.528 32.7297L305.382 57.9152C305.419 59.0186 305.4 59.8597 305.323 60.4386C305.293 60.9679 305.122 61.5981 304.811 62.3291C304.182 63.5991 302.548 64.2789 299.909 64.3684C297.319 64.4562 295.557 64.2038 294.625 63.6111C293.738 62.9687 293.202 62.0264 293.016 60.784C291.327 63.3867 288.396 64.7588 284.222 64.9004C280.097 65.0403 276.195 63.2755 272.516 59.6061C268.838 55.9367 266.914 51.6074 266.745 46.6182C266.574 41.5811 268.2 37.1315 271.623 33.2692C275.092 29.3574 278.962 27.3291 283.231 27.1843C284.862 27.129 286.359 27.3664 287.722 27.8965C289.085 28.4266 290.039 28.9466 290.585 29.4564C291.129 29.9182 291.577 30.3833 291.929 30.8516ZM279.34 46.2631C279.394 47.8462 280.066 49.2643 281.357 50.5172C282.647 51.7222 284.179 52.2946 285.954 52.2344C287.729 52.1742 289.17 51.477 290.278 50.1427C291.433 48.8067 291.985 47.3712 291.933 45.8361C291.879 44.253 291.254 42.8094 290.057 41.5052C288.908 40.1994 287.374 39.579 285.455 39.6441C283.584 39.7075 282.072 40.4312 280.918 41.8151C279.812 43.1974 279.286 44.6801 279.34 46.2631Z" fill="#FFCC59" />
          <path d="M335.824 25.7609C336.745 26.0178 337.506 26.4963 338.106 27.1964C338.752 27.8469 339.102 28.9397 339.154 30.4748C339.206 32.0099 338.807 33.7044 337.957 35.5582C337.108 37.4121 335.819 38.3683 334.092 38.4269C333.229 38.4562 332.383 38.2928 331.554 37.9366C330.774 37.5789 329.736 37.422 328.44 37.4659C327.145 37.5098 325.937 38.007 324.817 38.9576C323.745 39.9065 323.23 41.0285 323.274 42.3238L323.787 57.4351C323.824 58.5385 323.805 59.3796 323.728 59.9585C323.698 60.4879 323.503 61.1188 323.144 61.8514C322.467 63.1231 320.617 63.8102 317.595 63.9127C315.292 63.9908 313.623 63.6392 312.588 62.8578C311.798 62.2122 311.331 61.1954 311.188 59.8075C311.172 59.3278 311.148 58.6322 311.117 57.7207L310.26 32.4632C310.223 31.3598 310.219 30.5435 310.25 30.0142C310.326 29.4352 310.521 28.8043 310.834 28.1213C311.461 26.8033 313.31 26.0922 316.381 25.9881C319.259 25.8904 321.077 26.3811 321.834 27.4601C322.387 28.2097 322.678 28.9923 322.706 29.8079C322.934 29.4639 323.256 29.0448 323.672 28.5504C324.135 28.0544 325.097 27.3734 326.556 26.5075C328.015 25.6415 329.297 25.1898 330.4 25.1524C331.552 25.1134 332.514 25.1528 333.286 25.2707C334.057 25.3406 334.903 25.504 335.824 25.7609Z" fill="#FFCC59" />
          <path d="M342.545 13.5741C342.508 12.4707 342.504 11.6544 342.534 11.1251C342.611 10.5462 342.804 9.89121 343.116 9.16023C343.743 7.8422 345.592 7.13113 348.663 7.02701C351.589 6.92776 353.434 7.51357 354.197 8.78442C354.606 9.49099 354.843 10.1313 354.911 10.7053C355.026 11.2778 355.103 12.1156 355.14 13.219L356.27 46.5359C356.329 48.2629 356.51 49.3614 356.814 49.8313C357.117 50.2533 357.748 50.448 358.707 50.4155C359.714 50.3813 360.388 50.4065 360.727 50.4911C361.064 50.5277 361.478 50.7057 361.97 51.0253C362.95 51.6164 363.488 53.3031 363.582 56.0855C363.685 59.1078 363.267 60.971 362.331 61.6751C361.113 62.5809 358.143 62.8257 353.423 62.4095C349.712 62.055 347.272 60.9131 346.102 58.9836C344.679 56.6785 343.881 52.9595 343.707 47.8264L342.545 13.5741Z" fill="#FFCC59" />
          <path d="M367.936 58.8195C367.005 58.2748 366.518 57.3788 366.476 56.1315C366.434 54.8842 367.281 52.9584 369.018 50.354C369.518 49.5206 370.343 49.0843 371.495 49.0453C372.694 49.0046 374.321 49.5498 376.377 50.6808C378.431 51.7638 380.273 52.2776 381.904 52.2223C384.831 52.1231 386.274 51.4978 386.235 50.3464C386.204 49.435 384.574 48.8178 381.346 48.4951C378.309 48.1658 375.272 47.1402 372.236 45.4181C370.816 44.6018 369.619 43.2976 368.645 41.5055C367.671 39.6655 367.14 37.4742 367.054 34.9317C366.797 27.3521 371.873 23.3857 382.283 23.0327C385.689 22.9172 389.219 23.6139 392.872 25.123C394.577 25.8336 395.449 26.7646 395.488 27.9159C395.527 29.0673 395.048 30.5003 394.049 32.2152C393.051 33.93 392 34.8061 390.896 34.8435C390.321 34.8631 389.229 34.5399 387.622 33.874C386.062 33.2065 384.371 32.9036 382.548 32.9654C379.718 33.0614 378.32 33.6371 378.356 34.6925C378.405 36.1317 380.068 37.0118 383.344 37.3329C386.522 37.5614 389.623 38.3687 392.648 39.755C394.062 40.3794 395.253 41.5157 396.222 43.1638C397.236 44.7624 397.786 46.8089 397.871 49.3035C397.955 51.798 397.548 53.9491 396.649 55.7566C395.796 57.5145 394.544 58.8537 392.895 59.7741C389.875 61.3654 386.038 62.2399 381.385 62.3978C376.78 62.5539 372.297 61.3612 367.936 58.8195Z" fill="#FFCC59" />
          <path d="M423.01 35.1233L418.323 34.9941L418.66 44.9243C418.704 46.2196 418.927 47.1486 419.33 47.7112C419.782 48.2722 420.559 48.534 421.662 48.4966C422.814 48.4576 423.679 48.4763 424.258 48.5527C424.883 48.5795 425.538 48.7734 426.223 49.1344C427.347 49.7206 427.953 51.309 428.041 53.8995C428.144 56.9218 427.535 58.7915 426.214 59.5087C425.506 59.8689 424.866 60.1067 424.293 60.2222C423.767 60.2881 422.977 60.3389 421.921 60.3747C416.932 60.5439 413.104 59.545 410.437 57.3782C407.77 55.2113 406.345 51.4415 406.163 46.0685L405.802 35.4187C404.895 35.5935 403.914 35.6988 402.859 35.7346C401.804 35.7704 400.902 35.3927 400.155 34.6016C399.456 33.8088 399.073 32.429 399.006 30.4622C398.939 28.4953 399.011 27.076 399.222 26.2044C399.431 25.2848 399.745 24.6258 400.164 24.2273C400.91 23.5777 401.859 23.2333 403.01 23.1943L405.395 23.4016L405.143 15.9898C405.106 14.8865 405.102 14.0701 405.132 13.5408C405.21 13.0099 405.431 12.426 405.793 11.7894C406.376 10.5689 408.203 9.90664 411.273 9.80252C414.535 9.69189 416.508 10.5135 417.192 12.2673C417.458 13.0267 417.619 14.246 417.676 15.925L417.915 22.977C420.02 22.7135 421.599 22.5639 422.655 22.5281C423.758 22.4906 424.575 22.511 425.106 22.589C425.684 22.6175 426.339 22.8114 427.071 23.1708C428.34 23.7521 429.026 25.5779 429.13 28.6481C429.232 31.6704 428.647 33.5393 427.375 34.2548C426.667 34.615 426.027 34.8529 425.454 34.9683C424.928 35.0342 424.113 35.0858 423.01 35.1233Z" fill="#FFCC59" />
          <path d="M468.524 35.3087C468.623 38.2351 467.954 40.443 466.515 41.9326C465.123 43.3726 463.54 44.1227 461.765 44.1829L444.711 44.7613C444.756 46.1045 445.586 47.205 447.2 48.0628C448.814 48.9206 450.412 49.3227 451.996 49.269C454.778 49.1746 456.951 48.8128 458.515 48.1834L459.296 47.8687C460.43 47.302 461.38 47.0056 462.148 46.9796C463.683 46.9275 465.039 47.9621 466.215 50.0835C466.883 51.3576 467.232 52.4504 467.263 53.3619C467.408 57.6314 462.371 59.9394 452.153 60.286C448.603 60.4064 445.417 59.9141 442.593 58.8091C439.817 57.6546 437.627 56.0959 436.023 54.1331C432.869 50.3498 431.211 46.0596 431.048 41.2624C430.843 35.2178 432.623 30.3307 436.386 26.601C440.196 22.8217 445.028 20.8328 450.88 20.6343C457.549 20.4082 462.521 22.5929 465.799 27.1884C467.515 29.6277 468.423 32.3345 468.524 35.3087ZM453.411 37.9105C455.234 37.8486 456.12 37.0502 456.068 35.515C456.03 34.4117 455.568 33.5388 454.682 32.8965C453.844 32.2525 452.633 31.9574 451.05 32.0111C449.515 32.0632 448.002 32.7628 446.511 34.1102C445.018 35.4095 444.296 36.7788 444.345 38.2179L453.411 37.9105Z" fill="#FFCC59" />
          <path d="M493.812 19.9708C498.609 19.8081 502.654 21.5201 505.945 25.1066C509.283 28.6435 511.041 33.0504 511.22 38.3274L511.65 50.9921C511.687 52.0954 511.668 52.9366 511.591 53.5155C511.561 54.0448 511.39 54.651 511.076 55.334C510.449 56.652 508.6 57.3631 505.53 57.4672C502.124 57.5827 500.127 56.762 499.539 55.0049C499.223 54.1991 499.038 52.9807 498.983 51.3496L498.553 38.6849C498.486 36.718 497.859 35.2024 496.67 34.1381C495.529 33.0722 493.999 32.5717 492.08 32.6368C490.209 32.7003 488.694 33.328 487.533 34.52C486.421 35.7104 485.897 37.241 485.96 39.112L486.392 51.8486C486.43 52.952 486.41 53.7931 486.334 54.3721C486.304 54.9014 486.108 55.5084 485.747 56.193C485.167 57.5094 483.342 58.2197 480.272 58.3238C477.25 58.4263 475.381 57.8413 474.665 56.5688C474.305 55.8606 474.068 55.2443 473.954 54.7198C473.887 54.1458 473.834 53.3071 473.797 52.2037L472.933 26.7304C472.897 25.675 472.894 24.8826 472.925 24.3533C473.001 23.7744 473.22 23.1426 473.581 22.458C474.212 21.2359 476.038 20.5736 479.06 20.4711C481.987 20.3719 483.804 20.8625 484.514 21.9431C485.021 22.7424 485.294 23.7177 485.333 24.869C485.514 24.5267 485.929 24.0323 486.58 23.3859C487.23 22.7395 487.861 22.2138 488.471 21.8089C490.065 20.6502 491.845 20.0375 493.812 19.9708Z" fill="#FFCC59" />
        </svg>
      </div>
      <div className="mainheader__title-3d" style={{ opacity: opaque ? 1 : 0 }}>
        {loaded && <Logo3D secretMode={secretMode} />}
      </div>
    </div>
  )
}

export default Logo